import { Box, Typography, Tooltip } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { HelpOutline } from '@mui/icons-material';
import PaymentsIcon from '@mui/icons-material/Payments';
import PropTypes from 'prop-types';
import React from 'react';
import honorariumTypes from '../../../apps/preceptor/constans/honorariumTypes';
import rotationCostBreakdownStyles from './styles';

const RotationCostBreakdown = ({
  profile, hours, discount, extraCharges, initialTotal, total, dueToday, dueLater, hourlyRate, serviceFee,
  lastMinuteFee, paymentPlanFee, previousRequestsPaidAmount, hideBreakdown,
}) => {
  const classes = rotationCostBreakdownStyles();

  const formatPrice = price => price.toLocaleString();

  const totalDiscount = (discount || 0) + (previousRequestsPaidAmount || 0);
  return (
    <>
      <Box display="flex" alignItems="center" my={2}>
        <Box className={classes.blockDollar}>
          <MonetizationOnIcon className={classes.iconDollar} fontSize="small" />
        </Box>
        <Box display="flex" alignItems="baseline" justifyContent="space-between" width="100%">
          <Typography variant="h6">
            Total&nbsp;
          </Typography>
          <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="baseline">
            {initialTotal > total && !hideBreakdown && (
              <Typography
                variant="h6"
                color="secondary"
                className={initialTotal > total ? classes.discountedPrice : ''}
              >
                $
                {formatPrice((initialTotal))}
              </Typography>
            )}
            <Typography variant="h6" color="secondary" sx={{ marginLeft: 1 }}>
              $
              {formatPrice(total)}
            </Typography>
          </Box>
        </Box>
      </Box>
      {!hideBreakdown && (
        <>
          {profile.honorariumType?.key !== honorariumTypes.noHonorarium && (
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="subtitle2" className={classes.textHint}>
                  Hourly Rate:
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2">
                  $
                  {hourlyRate.toFixed(2).replace('.00', '')}
                  &nbsp;*&nbsp;
                  {hours}
                </Typography>
              </Box>
            </Box>
          )}
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="subtitle2" className={classes.textHint}>
                Application Fee:
                <Tooltip
                  className={{ tooltip: classes.tooltip }}
                  title={(
                    <Typography variant="subtitle2" className={classes.tooltipText}>
                      Covers app use, e-commerce, and admin services.
                    </Typography>
                  )}
                  arrow
                >
                  <HelpOutline sx={{ fontSize: '1rem', marginBottom: '-2px' }} />
                </Tooltip>
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                $
                {formatPrice(serviceFee)}
              </Typography>
            </Box>
          </Box>
          {paymentPlanFee > 0 && (
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="subtitle2" className={classes.textHint}>
                  Payment Plan Fee:
                  <Tooltip
                    className={{ tooltip: classes.tooltip }}
                    title={(
                      <Typography variant="subtitle2" className={classes.tooltipText}>
                        There is an additional 15% application fee for the Payment Plan.
                      </Typography>
                    )}
                    arrow
                  >
                    <HelpOutline sx={{ fontSize: '1rem', marginBottom: '-2px' }} />
                  </Tooltip>
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2">
                  $
                  {formatPrice(paymentPlanFee)}
                </Typography>
              </Box>
            </Box>
          )}
          {lastMinuteFee > 0 && (
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="subtitle2" className={classes.textHint}>
                  Last Minute Fee:
                  <Tooltip
                    className={{ tooltip: classes.tooltip }}
                    title={(
                      <Typography variant="subtitle2" className={classes.tooltipText}>
                        Based on when PAPERWORK is DUE, not when rotation starts. You receive priority over students.
                      </Typography>
                    )}
                    arrow
                  >
                    <HelpOutline sx={{ fontSize: '1rem', marginBottom: '-2px' }} />
                  </Tooltip>
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2">
                  $
                  {formatPrice(lastMinuteFee)}
                </Typography>
              </Box>
            </Box>
          )}
          {totalDiscount > 0 && (
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="subtitle2" className={classes.textHint}>
                  Discount:
                  {previousRequestsPaidAmount > 0 && (
                    <Tooltip
                      className={{ tooltip: classes.tooltip }}
                      title={(
                        <Typography variant="subtitle2" className={classes.tooltipText}>
                          Includes previously paid amount.
                        </Typography>
                      )}
                      arrow
                    >
                      <HelpOutline sx={{ fontSize: '1rem', marginBottom: '-2px' }} />
                    </Tooltip>
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2">
                  $
                  {(totalDiscount).toLocaleString()}
                </Typography>
              </Box>
            </Box>
          )}
          {extraCharges > 0 && (
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="subtitle2" className={classes.textHint}>
                  Additional Fee:
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2">
                  $
                  {formatPrice(extraCharges)}
                </Typography>
              </Box>
            </Box>
          )}
          <Box display="flex" alignItems="center" my={2}>
            <Box className={classes.blockDollar}>
              <PaymentsIcon className={classes.iconDollar} fontSize="small" />
            </Box>
            <Typography variant="h6">
              Your Payments:
            </Typography>
          </Box>
          <Box mt={1} display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="subtitle2" className={classes.textHint}>
                Security Deposit, Due Today:
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                $
                {formatPrice(dueToday)}
              </Typography>
            </Box>
          </Box>
          <Box mb={3} display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="subtitle2" className={classes.textHint}>
                Due Later:
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                $
                {formatPrice(dueLater)}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

RotationCostBreakdown.propTypes = {
  profile: PropTypes.shape({
    honorariumType: PropTypes.shape({
      key: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  hours: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  extraCharges: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  initialTotal: PropTypes.number.isRequired,
  dueToday: PropTypes.number.isRequired,
  dueLater: PropTypes.number.isRequired,
  hourlyRate: PropTypes.number.isRequired,
  serviceFee: PropTypes.number.isRequired,
  lastMinuteFee: PropTypes.number.isRequired,
  paymentPlanFee: PropTypes.number.isRequired,
  previousRequestsPaidAmount: PropTypes.number.isRequired,
  hideBreakdown: PropTypes.bool.isRequired,
};

export default RotationCostBreakdown;
