import React, { useState } from 'react';
import { useAuth } from 'reactfire';
import { useBackendUserState } from 'contexts/UserContext';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Box, Grid, Link, Typography, Container,
} from '@material-ui/core';
import FireAuth from 'shared/components/FireAuth';
import SignInForm from 'apps/account/components/SignInForm';
import SignUpForm from 'apps/account/components/SignUpForm';
import { openSelectRole } from 'actions/authDialogActions';
import DividerOr from 'shared/components/DividerOr';
import StressFreeBlock from 'apps/account/pages/CustomSearch/partials/StressFreeBlock';
import FirstBlock from 'apps/account/pages/CustomSearch/partials/FirstBlock';
import HowItWork from 'apps/account/pages/CustomSearch/partials/HowItWork';
import StepsBlock from 'apps/account/pages/CustomSearch/partials/StepsBlock';
import isEmpty from 'lodash/isEmpty';
import { REQUEST_CUSTOM_SEARCH } from 'shared/queries/account/queries';
import { USER_LOGGED_IN_MUTATION } from 'shared/queries/account/mutations';
import paths from 'paths';
import { Element } from 'react-scroll';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from 'firebase/auth';
import customSearchPageStyles from './styles';

const AUTH_ERRORS = {
  'auth/email-already-in-use': (
    <Box display="flex" mt={1}>
      <Typography variant="subtitle2" color="error">
        This email is already registered.&nbsp;
      </Typography>
    </Box>
  ),
};

const CustomSearchPage = () => {
  const classes = customSearchPageStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const auth = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const availableForms = {
    SIGN_UP: 'sign up',
    SIGN_IN: 'sign in',
  };

  const { state: { user: backendUser } } = useBackendUserState();
  const [activeForm, setActiveForm] = useState(availableForms.SIGN_UP);
  const [firebaseError, setFirebaseError] = useState(null);
  const [submitErrors, setSubmitErrors] = useState(null);

  const [userLoggedIn] = useMutation(USER_LOGGED_IN_MUTATION);
  const [requestCustomSearch] = useMutation(REQUEST_CUSTOM_SEARCH);

  const until = conditionFunction => {
    const poll = resolve => {
      if (conditionFunction) {
        resolve();
      } else {
        setTimeout(() => poll(resolve), 1000);
      }
    };

    return new Promise(poll);
  };

  const onSignInSubmit = data => {
    signInWithEmailAndPassword(auth, data.email, data.password).then(() => {
      requestCustomSearch();
      history.push(paths.rotationPaths.all.reverse());
      userLoggedIn({ variables: { description: 'to the "Application"' } });
    }).catch(reason => {
      setSubmitErrors(reason?.code);
    });
  };

  const onSignUpSubmit = data => {
    createUserWithEmailAndPassword(auth, data.email, data.password).then(userCredential => {
      const { user } = userCredential;
      const nickname = data.nickname ? data.nickname.trim() : '';
      updateProfile(user, {
        displayName: `${data.firstName.trim()} ${data.lastName.trim()} ${nickname}`.trim(),
      }).then(() => {
        requestCustomSearch();
        history.push(paths.wizardPaths.welcome.reverse());
      });
    }).catch(error => {
      // eslint-disable-next-line no-use-before-define
      const message = AUTH_ERRORS[error.code];
      setFirebaseError(message);
    });
  };

  const handleSuccess = async () => {
    // we have to wait until backend user loaded to figure out next step
    await until(() => !isEmpty(backendUser));
    requestCustomSearch();
    if (backendUser && backendUser?.hasRole === false) {
      dispatch(openSelectRole());
    } else {
      history.push(paths.rotationPaths.all.reverse());
    }
    userLoggedIn({ variables: { description: 'to the "Application" using a Google account' } });
  };

  const handleSetActiveForm = f => e => {
    e.preventDefault();
    setActiveForm(f);
  };

  return (
    <>
      <Box className={classes.backgroundBlock}>
        <Container>
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12} md={8}>
              <Box my={isMobile ? 2 : 9}>
                <FirstBlock />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Element name="form">
                <Box className={classes.block} mt={isMobile ? 0 : 6} mb={isMobile ? 1 : 3}>
                  <Typography variant={isMobile ? 'h6' : 'h5'}>
                    Request Custom Search
                  </Typography>
                  <Box mt={1} mb={2}>
                    <Typography variant="subtitle2" color="textSecondary">
                      You are just a small step away from finding Preceptor of your dreams for your rotation.
                    </Typography>
                  </Box>
                  {activeForm === availableForms.SIGN_UP && (
                    <SignUpForm onSubmit={onSignUpSubmit} buttonText="Request Custom Search" />
                  )}
                  {activeForm === availableForms.SIGN_IN && (
                    <SignInForm onSubmit={onSignInSubmit} submitErrors={submitErrors} />
                  )}
                  <DividerOr />
                  <FireAuth
                    auth={auth}
                    googleButtonText="Use Your Google Account"
                    linkedinButtonText="Use Your Linkedin Account"
                    onSignInSuccess={handleSuccess}
                  />
                  {firebaseError && (
                    <Box>{firebaseError}</Box>
                  )}
                  {activeForm === availableForms.SIGN_UP && (
                    <Box display="flex" mt={2} justifyContent="center">
                      <Typography variant="subtitle2">
                        Already have an account?
                        {' '}
                        <Link href="/" onClick={handleSetActiveForm(availableForms.SIGN_IN)}>Login</Link>
                      </Typography>
                    </Box>
                  )}
                  {activeForm === availableForms.SIGN_IN && (
                    <Box display="flex" mt={2} justifyContent="center">
                      <Typography variant="subtitle2">
                        Don&apos;t have an account?
                        {' '}
                        <Link href="/" onClick={handleSetActiveForm(availableForms.SIGN_UP)}>Sign Up</Link>
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Element>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Box mt={2}>
              <HowItWork />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mt={isMobile ? 0 : 8}>
              <StepsBlock />
            </Box>
          </Grid>
        </Grid>
        <Box my={isMobile ? 3 : 11}>
          <StressFreeBlock />
        </Box>
      </Container>
    </>
  );
};

export default CustomSearchPage;
