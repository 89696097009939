import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import truncate from 'lodash/truncate';
import { useBackendDataState } from 'contexts/DataContext';
import CustomSpecialty from './partials/CustomSpecialty';

const AutocompleteSpecialty = ({
  value, onChange, length, error, helperText, label, className, disabled,
}) => {
  const { state: { specialty = [] } = {} } = useBackendDataState();
  const options = specialty.map(s => ({ group: s.group, value: s.key.toString(), title: s.value }));
  const selectedValue = value ? options.filter(el => value.includes(el.value)) : [];

  const handleChange = (event, val) => {
    const selectValues = val.map(el => el.value);
    onChange(selectValues);
  };

  const renderTags = val => truncate(val.map(el => el.title).join(', '), { length });

  return (
    <Autocomplete
      disableClearable
      multiple
      value={selectedValue}
      disableCloseOnSelect
      onChange={handleChange}
      options={options}
      groupBy={option => option.group}
      getOptionLabel={option => option.title}
      renderTags={renderTags}
      disabled={disabled}
      renderInput={params => (
        <CustomSpecialty
          {...params}
          label={label}
          variant="filled"
          fullWidth
          error={error}
          helperText={helperText}
          required={selectedValue.length === 0}
          className={className}
        />
      )}
    />
  );
};

AutocompleteSpecialty.defaultProps = {
  length: 25,
  error: false,
  helperText: '',
  required: false,
  label: 'Specialty',
  className: '',
  disabled: false,
};

AutocompleteSpecialty.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  length: PropTypes.number,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default AutocompleteSpecialty;
