import React from 'react';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import useQueryString from 'shared/hooks/useQueryString';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  Box, Typography, Button, Dialog, DialogContent,
} from '@material-ui/core';
import { useUser } from 'reactfire';
import { closeAll } from 'actions/authDialogActions';
import { SAVE_ROTATION_STEP_MUTATION } from 'apps/wizard/graphql/mutations';
import { CREATE_STUDENT, CREATE_PRECEPTOR, ME } from 'shared/queries/account/queries';
import { formatIsoDate } from 'shared/helpers/dateHelpers';
import ButtonProgress from 'shared/components/ButtonProgress';
import DialogTitle from 'shared/components/DialogTitle';
import DividerOr from 'shared/components/DividerOr';
import paths from 'paths';

export default function DialogWelcome() {
  const dispatch = useDispatch();
  const { data: user } = useUser();
  const history = useHistory();
  const { urlParams } = useQueryString();
  const location = useLocation();

  const isSelectRoleOpen = useSelector(
    state => state.authDialogsState.isSelectRoleOpen, shallowEqual,
  );

  const isLocationOpen = location?.pathname === paths.wizardPaths.welcome.reverse();

  const getSplittedFullName = () => {
    const fullName = ['', '', ''];
    const splitted = user?.displayName?.split(' ');

    if (splitted?.length > 2) {
      fullName.splice(0, 1, splitted[0]);
      fullName.splice(1, 1, splitted[1]);
      fullName.splice(2, 1, splitted[2]);
    } else if (splitted?.length === 2) {
      fullName.splice(0, 1, splitted[0]);
      fullName.splice(1, 1, splitted[1]);
    } else if (splitted?.length === 1) {
      fullName.splice(0, 1, splitted[0]);
    }
    return fullName;
  };

  const [saveRotationStepMutation] = useMutation(SAVE_ROTATION_STEP_MUTATION);

  const [createStudentMutation, { loading: loadingStudent }] = useMutation(CREATE_STUDENT, {
    variables: {
      firstName: getSplittedFullName()[0],
      lastName: getSplittedFullName()[1],
      nickname: getSplittedFullName()[2],
    },
    refetchQueries: [ME],
    onCompleted: () => {
      dispatch(closeAll());
      if (urlParams.hours || (urlParams.dateFrom && urlParams.dateTo) || urlParams.specialty) {
        const data = {};
        const msPerDay = 1000 * 60 * 60 * 24;
        const startDate = urlParams.dateFrom && new Date(`${urlParams.dateFrom}T00:00`);
        const defaultPaperworkDueDate = startDate && new Date(`${formatIsoDate(new Date(startDate - msPerDay * 30))}T00:00`);
        if (urlParams.rotationNote) data.rotationNote = urlParams.rotationNote;
        if (urlParams.hours) data.hours = urlParams.hours;
        if (urlParams.dateFrom && urlParams.dateTo) {
          data.dateRange = [urlParams.dateFrom, urlParams.dateTo];
          data.paperworkDueDate = urlParams.paperworkDueDate || defaultPaperworkDueDate;
        }
        if (urlParams.specialty) {
          data.specialty = Array.isArray(urlParams.specialty) ? urlParams.specialty : [urlParams.specialty];
        }
        if (urlParams.preceptorID) {
          data.preceptorID = urlParams.preceptorID;
        }
        if (urlParams.whichRotationThisWillBeForStudent) {
          data.whichRotationThisWillBeForStudent = urlParams.whichRotationThisWillBeForStudent;
        }
        if (urlParams.reserve) {
          data.reserve = urlParams.reserve;
        }
        saveRotationStepMutation({ variables: { submittedData: JSON.stringify(data) } });
      }
      history.push(paths.wizardPaths.welcome.reverse());
    },
  });

  const [createPreceptorMutation, { loading: loadingPreceptor }] = useMutation(CREATE_PRECEPTOR, {
    variables: {
      firstName: getSplittedFullName()[0],
      lastName: getSplittedFullName()[1],
      nickname: getSplittedFullName()[2],
    },
    refetchQueries: [ME],
    onCompleted: () => {
      dispatch(closeAll());
      history.push(paths.wizardPaths.welcome.reverse());
    },
  });

  const handleClickStudent = () => {
    createStudentMutation();
  };

  const handleClickPreceptor = () => {
    createPreceptorMutation();
  };

  const handleClose = () => false;

  const isButtonDisabled = loadingPreceptor || loadingStudent;

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isSelectRoleOpen && isLocationOpen}>
      <DialogTitle id="customized-dialog-title">
        Welcome to Preceptorlink
      </DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography variant="subtitle2" color="textSecondary">
            Select the type of user to register
          </Typography>
        </Box>
        <Button
          variant="contained"
          size="large"
          fullWidth
          onClick={handleClickStudent}
          disabled={isButtonDisabled}
        >
          I am a Student
          <ButtonProgress isLoading={loadingStudent} />
        </Button>
        <DividerOr />
        <Box mb={4}>
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={handleClickPreceptor}
            disabled={isButtonDisabled}
          >
            I am a Preceptor
            <ButtonProgress isLoading={loadingPreceptor} />
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
