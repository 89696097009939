import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import typography from '../../../../../theme/typography';

const smallPreceptorCardStyles = makeStyles(theme => createStyles({
  block: {
    background: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    '&:hover': {
      background: theme.palette.grey[50],
      cursor: 'pointer',
    },
    [theme.breakpoints.up('xs')]: {
      padding: '8px',
      maxWidth: 'auto',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      maxWidth: 'auto',
    },
    '& .MuiChip-root': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: '100%',
    },
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.spacing(1),
    margin: theme.spacing(1),
  },
  chip: {
    color: '#616161',
  },
  chipAvailable: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.colors.lightPrimaryBlue,
  },
  chipNotAvailable: {
    color: theme.palette.colors.red,
    backgroundColor: theme.palette.colors.lightOrange,
  },
  chipPartialAvailable: {
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.colors.lightOrange,

  },
  section: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  divider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  idText: {
    ...typography.subtitle3,
    color: theme.palette.text.secondary,
  },
  label: {
    color: '#858585',
    fontSize: '0.75rem',
  },
  value: {
    color: '#616161',
    fontSize: '0.875rem',
  },
}));

export default smallPreceptorCardStyles;
