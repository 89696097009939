import React, { useState, useEffect } from 'react';
import { useAuth } from 'reactfire';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { useBackendUserState } from 'contexts/UserContext';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import {
  Box, Typography, Link, Dialog, DialogContent,
} from '@material-ui/core';
import {
  closeAll,
  openSignIn,
  openSignUp,
  openForgotPassword,
  openSelectRole,
} from 'actions/authDialogActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import DialogTitle from 'shared/components/DialogTitle';
import DividerOr from 'shared/components/DividerOr';
import FireAuth from 'shared/components/FireAuth';
import SignInForm from 'apps/account/components/SignInForm';
import SignUpForm from 'apps/account/components/SignUpForm';
import useQueryString from 'shared/hooks/useQueryString';
import { USER_LOGGED_IN_MUTATION } from 'shared/queries/account/mutations';
import paths from 'paths';
import isEmpty from 'lodash/isEmpty';

const SignInDialog = () => {
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { urlParams } = useQueryString();

  const isSignInOpen = useSelector(state => state.authDialogsState.isSignInOpen, shallowEqual);
  const isSignUpOpen = useSelector(state => state.authDialogsState.isSignUpOpen, shallowEqual);

  const [userLoggedIn, { data: userLoggedInResponse }] = useMutation(USER_LOGGED_IN_MUTATION);

  const [firebaseError, setFirebaseError] = useState(null);
  const { state: { user: backendUser } } = useBackendUserState();

  useEffect(() => {
    if (userLoggedInResponse?.userLoggedIn?.status === 'Success'
      && backendUser?.wizardState?.isWizardCompleted
      && backendUser?.lastUsedRole?.key === 'student'
      && !urlParams?.noRedirect
    ) {
      history.push(paths.rotationPaths.all.reverse());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedInResponse, backendUser]);

  const until = conditionFunction => {
    const poll = resolve => {
      if (conditionFunction) {
        resolve();
      } else {
        setTimeout(() => poll(resolve), 1000);
      }
    };

    return new Promise(poll);
  };

  const handleSuccess = async () => {
    // we have to wait until backend user loaded to figure out next step
    await until(() => !isEmpty(backendUser));
    if (backendUser && backendUser?.hasRole === false) {
      dispatch(closeAll());
      dispatch(openSelectRole());
    } else {
      dispatch(closeAll());
    }
    userLoggedIn({ variables: { description: 'to the "Application" using a Google account' } });
  };

  const [submitErrors, setSubmitErrors] = useState('');

  const handleClose = () => {
    setSubmitErrors('');
    dispatch(closeAll());
  };

  const onSignInSubmit = data => {
    signInWithEmailAndPassword(auth, data.email, data.password).then(() => {
      handleClose();
      userLoggedIn({ variables: { description: 'to the "Application"' } });
    }).catch(reason => {
      setSubmitErrors(reason?.code);
    });
  };

  const onSignUpSubmit = data => {
    createUserWithEmailAndPassword(auth, data.email, data.password).then(userCredential => {
      const { user } = userCredential;
      const nickname = data.nickname ? data.nickname.trim() : '';
      updateProfile(user, {
        displayName: `${data.firstName.trim()} ${data.lastName.trim()} ${nickname}`.trim(),
      }).then(() => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.append('reserve', 'true');
        history.push({ pathname: paths.wizardPaths.welcome.reverse(), search: searchParams.toString() });
        handleClose();
      });
    }).catch(error => {
      // eslint-disable-next-line no-use-before-define
      const message = AUTH_ERRORS[error.code];
      setFirebaseError(message);
    });
  };

  const handleOpenSignIn = e => {
    e.preventDefault();
    setFirebaseError(null);
    dispatch(openSignIn());
  };

  const handleOpenSignUp = e => {
    e.preventDefault();
    setFirebaseError(null);
    setSubmitErrors('');
    dispatch(openSignUp());
  };

  const handleOpenForgotPassword = e => {
    e.preventDefault();
    setFirebaseError(null);
    setSubmitErrors('');
    dispatch(openForgotPassword());
  };

  const isDialogOpen = isSignInOpen || isSignUpOpen;

  const AUTH_ERRORS = {
    'auth/email-already-in-use': (
      <Box display="flex" mt={1}>
        <Typography variant="subtitle2" color="error">
          This email is already registered.&nbsp;
          <Link href="/" onClick={handleOpenSignIn}>Login</Link>
          &nbsp;or&nbsp;
          <Link href="/" onClick={handleOpenForgotPassword}>Recover Your Password</Link>
        </Typography>
      </Box>
    ),
  };

  useEffect(() => () => {
    setFirebaseError(null);
  }, []);

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isDialogOpen}>
      <Box mb={2}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {isSignInOpen && 'Login'}
          {isSignUpOpen && 'Sign Up'}
        </DialogTitle>
      </Box>
      <DialogContent>
        {isSignUpOpen && (
          <Box mb={2}>
            <Typography variant="subtitle2" color="textSecondary">
              You are just a small step away from finding Preceptor of your dreams for your rotation.
            </Typography>
          </Box>
        )}

        {isSignInOpen && <SignInForm onSubmit={onSignInSubmit} submitErrors={submitErrors} /> }
        {isSignUpOpen && <SignUpForm onSubmit={onSignUpSubmit} /> }

        {firebaseError && (
          <Box>{firebaseError}</Box>
        )}

        <DividerOr />

        {isSignInOpen && (
          <FireAuth
            auth={auth}
            googleButtonText="Login with Google Account"
            linkedinButtonText="Login with Linkedin Account"
            onSignInSuccess={handleSuccess}
            // signInSuccessUrl={paths.rotationPaths.all.reverse()}
          />
        )}
        {isSignUpOpen && (
          <FireAuth
            auth={auth}
            googleButtonText="Use Your Google Account"
            linkedinButtonText="Use Your Linkedin Account"
            onSignInSuccess={handleSuccess}
            // signInSuccessUrl={paths.wizardPaths.welcome.reverse()}
          />
        )}

        <Box display="flex" mt={2} justifyContent="center" mb={4}>
          {isSignInOpen && (
            <Typography variant="subtitle2">
              Don&apos;t have an account?
              {' '}
              <Link href="/" onClick={handleOpenSignUp}>Sign Up</Link>
              <Box textAlign="center">
                <Link href="/" onClick={handleOpenForgotPassword}>Forgot Password?</Link>
              </Box>
            </Typography>
          )}

          {isSignUpOpen && (
            <Typography variant="subtitle2">
              Already have an account?
              {' '}
              <Link href="/" onClick={handleOpenSignIn}>Login</Link>
            </Typography>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SignInDialog;
