import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Grid, Button, Link, FormControl, FormHelperText,
  TextField, Checkbox, FormControlLabel,
} from '@material-ui/core';
import * as yup from 'yup';

const schema = yup.object().shape({
  firstName: yup.string().required('Field is required')
    .test(
      'not-only-spaces',
      'First Name cannot contain only spaces',
      value => value && value.trim().length > 0 && value.length > 0,
    )
    .test(
      'contains-space',
      'Please use  “-“ for hyphenated name',
      value => value && !/\s/.test(value.trim()),
    ),
  lastName: yup.string().required('Field is required')
    .test(
      'not-only-spaces',
      'Last Name cannot contain only spaces',
      value => value && value.trim().length > 0 && value.length > 0,
    )
    .test(
      'contains-space',
      'Please use  “-“ for hyphenated name',
      value => value && !/\s/.test(value.trim()),
    ),
  nickname: yup.string().test(
    'not-only-spaces-nickname',
    'Nickname cannot contain only spaces',
    value => {
      // eslint-disable-next-line
      if(value === undefined) value = '';
      return value === '' || (value.trim().length > 0 && value.length > 0);
    },
  ).test(
    'contains-space-nickname',
    'Please use  “-“ for hyphenated name',
    value => {
      // eslint-disable-next-line
      if(value === undefined) value = '';
      return value === '' || (value && !/\s/.test(value.trim()));
    },
  ),
  email: yup.string().email('Email must be a valid email').required('Field is required'),
  password: yup.string()
    .required('Field is required')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  termsAndConditions: yup.boolean()
    .oneOf([true], 'Please accept the terms of services to continue.'),
});

const SignUpForm = ({ onSubmit, buttonText }) => {
  // const dispatch = useDispatch();

  const {
    control, register, handleSubmit, formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
    defaultValues: {
      termsAndConditions: false,
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="fname"
            name="firstName"
            variant="filled"
            fullWidth
            id="firstName"
            label="First Name"
            autoFocus
            {...register('firstName')}
            error={Boolean(errors.firstName?.message)}
            helperText={errors.firstName?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="filled"
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="lname"
            {...register('lastName')}
            error={Boolean(errors.lastName?.message)}
            helperText={errors.lastName?.message}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            variant="filled"
            fullWidth
            id="nickname"
            label="Preferred Name/Nickname"
            name="nickname"
            autoComplete="nname"
            {...register('nickname')}
            error={Boolean(errors.nickname?.message)}
            helperText={errors.nickname?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            {...register('email')}
            error={Boolean(errors.email?.message)}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            {...register('password')}
            error={Boolean(errors.password?.message)}
            helperText={errors.password?.message}
          />
        </Grid>
      </Grid>
      <Box mb={1} display="flex" ml={-1.5}>
        <Box>
          <Controller
            name="termsAndConditions"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl required error={Boolean(errors.termsAndConditions?.message)}>
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    control={(
                      <Checkbox
                        value={value}
                        onChange={e => onChange(e.target.checked)}
                        color="primary"
                      />
                    )}
                    label="I agree with the"
                  />
                  <Box display="flex" alignItems="center" height="20px">
                    <Link href="https://www.preceptorlink.com/terms-and-conditions.html" target="_blank">
                      Terms & Conditions
                    </Link>
                  </Box>
                </Box>
                <Box ml={2}>
                  {errors.termsAndConditions
                  && <FormHelperText>{errors.termsAndConditions?.message}</FormHelperText>}
                </Box>
              </FormControl>
            )}
          />
        </Box>
      </Box>
      <Button type="submit" fullWidth variant="contained">
        {buttonText}
      </Button>
    </form>
  );
};

SignUpForm.defaultProps = {
  buttonText: 'Continue',
};

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

export default SignUpForm;
