import React from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import { useUser, useAuth } from 'reactfire';
import { useHistory } from 'react-router-dom';

import { useBackendUserState } from 'contexts/UserContext';
import {
  Box, Typography, Button, MenuItem, Divider, Avatar, Menu,
} from '@material-ui/core';
// import { Alert } from '@material-ui/lab';
import LoopIcon from '@material-ui/icons/Loop';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { USER_LOGGED_OUT_MUTATION } from 'shared/queries/account/mutations';
import clsx from 'clsx';
import paths from 'paths';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import userMenuStyles from './styles';

const UserMenu = () => {
  const classes = userMenuStyles();
  const history = useHistory();
  const client = useApolloClient();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const auth = useAuth();
  const { data: user } = useUser();
  const { state: { user: backendUser, called: backendUserCalled } } = useBackendUserState();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const wizardNotCompleted = !backendUser?.wizardState?.isWizardCompleted;

  const [userLoggedOut] = useMutation(USER_LOGGED_OUT_MUTATION);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = url => () => {
    history.push(url);
    handleClose();
  };

  const handleSignOut = () => {
    auth.signOut().then(() => {
      client.resetStore();
      handleClose();
      history.push('/');
      userLoggedOut({
        variables: {
          firebaseUid: backendUser?.firebaseUid,
          description: 'from the "Application"',
        },
      });
    });
  };

  if (!backendUser) {
    if (!backendUserCalled) return null;
    return (
      <Button onClick={handleSignOut} className={classes.buttonSignOut}>
        Sign Out
      </Button>
    );
  }

  if (wizardNotCompleted) {
    return (
      <Button onClick={handleSignOut} className={classes.buttonSignOut}>
        Sign Out
      </Button>
    );
  }

  const avatarUrl = backendUser[backendUser?.lastUsedRole?.key]?.profile?.avatar;

  const handleNickName = displayName => {
    if (displayName) {
      if (displayName !== backendUser?.getName) {
        const parts = displayName.split(' ');
        if (parts.length > 2) {
          const lastPart = parts.pop();
          return `${parts.join(' ')} (${lastPart})`;
        }
      }
      return displayName;
    }
    return '';
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          <Box display="flex">
            <Box pr={1} className={classes.avatar}>
              {avatarUrl ? (
                <Avatar variant="rounded" src={avatarUrl} alt="avatar" className={classes.image} />
              ) : (
                <AccountCircleIcon className={classes.iconAvatar} />
              )}
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography variant="subtitle2" color="textPrimary" className={classes.name}>{handleNickName(user?.displayName || user?.email)}</Typography>
              {backendUser?.lastUsedRole?.value && (
                <Typography variant="caption" color="textPrimary" className={classes.role}>
                  {backendUser?.lastUsedRole?.value}
                </Typography>
              )}
            </Box>
          </Box>
          <ExpandMoreIcon className={clsx(classes.icon, {
            [classes.iconActive]: Boolean(anchorEl),
          })}
          />
        </Button>
        <Menu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 0,
            horizontal: 'center',
          }}
          getContentAnchorEl={null}
          PopoverClasses={{ paper: classes.popover }}
        >
          <Box mt={0.5} mb={1}>
            {isMobile && (
              <>
                <MenuItem
                  onClick={handleMenuItemClick('/rotations/all/')}
                  className={classes.buttonIcon}
                >
                  <LoopIcon className={classes.icon} />
                  My Rotations
                </MenuItem>
                <MenuItem
                  onClick={handleMenuItemClick(`/${backendUser.lastUsedRole?.key}/profile/`)}
                  className={classes.buttonIcon}
                >
                  <AccountCircleOutlinedIcon className={classes.icon} />
                  My Profile
                </MenuItem>
              </>
            )}
            {backendUser.lastUsedRole?.key === 'student' && (
              <>
                <MenuItem
                  onClick={handleMenuItemClick(paths.financesPaths.myCards.reverse())}
                  className={classes.buttonIcon}
                >
                  <CreditCardOutlinedIcon className={classes.icon} />
                  Finances
                </MenuItem>
                <Box mt={2} mb={1}>
                  <Divider />
                </Box>
              </>
            )}
            <MenuItem onClick={handleSignOut} className={classes.buttonIcon}>
              <ExitToAppOutlinedIcon className={classes.icon} />
              Sign Out
            </MenuItem>
          </Box>
        </Menu>
      </Box>
    </Box>
  );
};

export default UserMenu;
